import * as React from "react"
import { useEffect } from 'react';
import Layout from "../../templates/layout-landing-page"
import Seo from "../../components/seo"
import "../../assets/css/screen.css"
import uvmLogo from '../../assets/img/welcome/ntm-lp/uvm-mvp-logo.svg';
import uvmCheckmarkImage from '../../assets/img/checkmark-tertiary-icn.svg';
// import uvmStars from '../../assets/img/welcome/ntm-lp/medicare-star-rating-rgb-uvm.svg';
// import uvmUSNewsVT from '../../assets/img/welcome/ntm-lp/badge-med-adv-2022-vt.svg';
// import uvmUSNewsNY from '../../assets/img/welcome/ntm-lp/badge-med-adv-2022-ny.svg';
import uvmWorkTogetherCTA from '../../assets/img/welcome/ntm-lp/uvm-mvp-ntm-lp-planguide-img-d-1-5x.jpg';
import SideBarCard from '../../components/ui/SideBarCard';

const NTMQuickGuide = ({ location }) => {

	useEffect (() => {
		window.MsCrmMkt = null;
		const dynFormScript = document.createElement("script");
		dynFormScript.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.80.2009.0";
		dynFormScript.async = false;
		document.body.appendChild(dynFormScript);

		const dynTrackScript = document.createElement("script");
		dynTrackScript.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.80.2009.0";
		dynTrackScript.async = false;
		document.body.appendChild(dynTrackScript);
	},[]);

	return (
		<Layout location={location} heading1="" heading2="">

			<Seo title="Request a UVM Health Advantage Quick Guide to Medicare" meta={[{description: 'UVM Health Advantage is a new Medicare Advantage option, guided by doctors at the UVM Health Network and Medicare experts at MVP Health Care.'}]} bodyclass="ntm-lp quickguide" />
		
			<div className="topper">
				<div className="constrained">
					<div className="logo">
						<img src={uvmLogo} alt="UVM Health &amp; MVP Health Care" width="250" height="49" />
					</div>
				</div>
			</div>
			
			<header>
				<div className="constrained">
					<div className="content">
						<h1>Get to know your Medicare options.</h1>
						<h2>UVM Health Advantage wants to help you get the care and support you need to take on your health goals and challenges to live your best life.</h2>	
					</div>
				</div>
			</header>

			<main>
				<div className="constrained">
					<div className="flexwrap">
						<div className="formwrap">
							<h3>Request your FREE copy of the UVM Health Advantage Quick Guide to Medicare.</h3>
							<div className="form" dangerouslySetInnerHTML={{ __html: process.env.GATSBY_NTM_QUICK_GUIDE_LP }}></div>
						</div>
						<div className="listwrap">
							<h4>With our Quick Guide to Medicare you can: </h4>
							<ul>
								<li><img src={uvmCheckmarkImage} alt="A checkmark" width="97" height="97" /> Explore the ABCs of Medicare</li>
								<li><img src={uvmCheckmarkImage} alt="A checkmark" width="97" height="97" /> Review important dates and deadlines </li>
								<li><img src={uvmCheckmarkImage} alt="A checkmark" width="97" height="97" /> Learn what to do if you&rsquo;re not retiring</li>
								{/* <li><img src={uvmCheckmarkImage} alt="A checkmark" width="97" height="97" /> Review important steps regarding Marketplace and Employer coverage.</li> */}
							</ul>
						</div>
					</div>
				</div>
			</main>

			<aside>

				<div className="cardwrap">
					<div className="constrained">
						<div className="flexwrap">
							<SideBarCard type="basics" />
							<SideBarCard type="watchvids" />
							<SideBarCard type="connect" />
						</div>
					</div>
				</div>
				
				<div className="constrained">
					<div className="tertiary-cta">
						<div className="planguide"><img src={uvmWorkTogetherCTA} alt="" width="720" height="843" /></div>
						<div className="content">
							<p className="head"><b>Let&rsquo;s work together.</b></p>
							<p>Our UVM Health Advantage Plan Guides can personally help you understand your options, find the right plan and make sure your transition to your new plan goes smoothly with no disruption to your care.</p>
							<p className="subhead">1-833-825-5886 <span>(TTY 711)</span></p>
							<p>October 1-March 31, seven days a week, 8 a.m.-8 p.m. Eastern Time.<br/>
							April 1-September 30, Monday-Friday, 8 a.m.-8 p.m.</p>
						</div>
					</div>
				</div>
				
				{/* <div className="awards">
					<div className="constrained">
						<ul>
							<li><img src={uvmStars} alt="4.5 Stars out of 5 Stars Overall by Medicare 2022" width="198" height="144" /></li>
							<li><img src={uvmUSNewsVT} alt="Best Insurance Companies - Medicare Advantage Vermont 2022" width="198" height="144" /></li>
							<li><img src={uvmUSNewsNY} alt="Best Insurance Companies - Medicare Advantage Vermont 2022" width="198" height="144" /></li>
						</ul>
					</div>
				</div> */}

			</aside>
		</Layout>
	)
}

export default NTMQuickGuide
